import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Observable,
    throwError as observableThrowError
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModel } from '../common.model';
import { GlobalAPIConstants } from '../global-api-constants';
import { GlobalConstants } from '../global-constants';


@Injectable()
export class HttpService {
    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
    ) {

    }
    DeepTrim(obj) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                const value = obj[prop];
                const type = typeof value;
                if (value != null && (type === 'string' || type === 'object') && obj.hasOwnProperty(prop)) {
                    if (type === 'object') {
                        this.DeepTrim(obj[prop]);
                    } else {
                        obj[prop] = obj[prop].trim();
                    }
                }
            }
        }
        return obj;
    }

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const array = [];
        for (let i = 0; i < byteString.length; i++) {
            array.push(byteString.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mimeString });
    }

    blobToFile(theBlob: Blob, fileName): File {
        const b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;

        // Cast to a File() type
        return theBlob as File;
    }

    postWithFile<TReturn>(apiPath: string, fieldName: string[], files: File[]): Observable<any> {
        const formData: FormData = new FormData();
        let i = 0;
        files.forEach(data => {
            if (fieldName[i] === null && data.name !== undefined && data.name !== null) {
                fieldName[i] = data.name;
            }
            formData.append(fieldName[i], data, fieldName[i]);
            i++;
        });
        let header = this.getDefaultRequestHeaders();
        header = header.set('X-Requested-With', 'XMLHttpRequest');
        header = header.delete('Content-Type');
        return this.http.post(GlobalAPIConstants.Host + apiPath, formData, {
            headers: header
        }).pipe(map(this.extractData), catchError(this.handleError));
    }
    post<TReturn>(apiPath: string, requestObject: object, isAuth?: boolean): Observable<TReturn> {

        const postHeaderOptions = {
            headers: this.getDefaultRequestHeaders(isAuth)
        };

        return this.http.post(GlobalAPIConstants.Host + apiPath, this.DeepTrim(requestObject), postHeaderOptions).pipe(
            map(this.extractData), catchError(this.handleError));
    }
    put<TReturn>(apiPath: string, putObject?: object, Options?: any): Observable<TReturn> {
        const putHeaderOptions = {
            headers: this.getDefaultRequestHeaders()
        };

        return this.http.put(GlobalAPIConstants.Host + apiPath, this.DeepTrim(putObject), putHeaderOptions).pipe(
            map(this.extractData),
            catchError(this.handleError));
    }

    delete<TReturn>(apiPath: string, isAuth?: boolean): Observable<any> {
        const deleteHeaderOptions = {
            headers: this.getDefaultRequestHeaders(isAuth)
        };
        return this.http.delete(GlobalAPIConstants.Host + apiPath, deleteHeaderOptions).pipe(
            map(this.extractData),
            catchError(this.handleError));
    }

    get<TReturn>(apiPath: string, isAuth?: boolean): Observable<any> {
        const getHeaderOptions = {
            headers: this.getDefaultRequestHeaders(isAuth),
        };

        return this.http.get(GlobalAPIConstants.Host + apiPath, getHeaderOptions).pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    // Simple GET request with no predefined values
    getFile(url: string): Observable<any> {
        const options: any = {
            method: 'GET',
            responseType: 'arrayBuffer',
            headers: new HttpHeaders(GlobalAPIConstants.Headers.ContentTypes.ApplicationOctetStream)
        };

        return this.http.request(url, options).pipe(
            map(this.extractData),
            catchError(this.handleError));
    }

    getBase64(apiPath: string, isAuth?: boolean): Observable<any> {
        const options: any = {
            responseType: 'arrayBuffer',
            headers: this.getDefaultRequestHeaders(isAuth)
        };
        return this.http.get(GlobalAPIConstants.Host + apiPath, options).pipe(
            map((res: any) => {
                const base64String = String.fromCharCode.apply(null, new Uint8Array(res));
                return base64String;
            }), catchError(this.handleError));
    }

    getDefaultRequestHeaders(isAuth?: boolean): HttpHeaders {
        const loginInfoModel = localStorage.getItem(GlobalConstants.LocalStorageKey.oAuthToken);
        const currentLang = (this.translateService.currentLang) ?
            this.translateService.currentLang : this.translateService.defaultLang;

        let currentLangISO = 'en-GB';
        if (currentLang === 'no') {
            currentLangISO = 'nb-NO';
        }

        let headers = new HttpHeaders({
            'Content-Type': GlobalAPIConstants.HeaderValues.ApplicationJSON,
            'x-app-version': GlobalAPIConstants.HeaderValues.AppVersion,

            'accept-language': currentLangISO,
            'x-api-version': GlobalAPIConstants.HeaderValues.APIVersion,
            'Ocp-Apim-Subscription-Key': GlobalAPIConstants.HeaderValues.OcpAPIM,
        });
        if (isAuth) {
            headers = headers.set(
                'X-Auth-Key', GlobalAPIConstants.HeaderValues.AuthKey
            );
        }
        if (loginInfoModel) {
            headers = headers.set(
                'Authorization', 'Bearer ' + loginInfoModel
            );
        }

        return headers;
    }
    getWithPlainText(isAuth?: boolean): HttpHeaders {
        const loginInfoModel = localStorage.getItem(GlobalConstants.LocalStorageKey.oAuthToken);
        const currentLang = (this.translateService.currentLang) ?
            this.translateService.currentLang : this.translateService.defaultLang;

        let currentLangISO = 'en-GB';
        if (currentLang === 'no') {
            currentLangISO = 'nb-NO';
        }

        let headers = new HttpHeaders({
            'Content-Type': 'text/plain',
            'x-app-version': GlobalAPIConstants.HeaderValues.AppVersion,

            'accept-language': currentLangISO,
            'x-api-version': GlobalAPIConstants.HeaderValues.APIVersion,
            'Ocp-Apim-Subscription-Key': GlobalAPIConstants.HeaderValues.OcpAPIM,
        });
        if (isAuth) {
            headers = headers.set(
                'X-Auth-Key', GlobalAPIConstants.HeaderValues.AuthKey
            );
        }
        if (loginInfoModel) {
            headers = headers.set(
                'Authorization', 'Bearer ' + loginInfoModel
            );
        }

        return headers;
    }
    download(apiPath: string, searchParams?: any, isAuth?: boolean): Observable<any> {
        const options: any = {
            headers: this.getDefaultRequestHeaders(isAuth)
        };
        options.responseType = 'arrayBuffer';


        return this.http.get(GlobalAPIConstants.Host + apiPath, options).pipe(
            map((res: any) => {

                return new Blob([res]);
            }));
    }
    downloadPost(apiPath: string, body?: any, isAuth?: boolean): Observable<any> {
        const options: any = {
            headers: this.getDefaultRequestHeaders(isAuth)
        };
        options.responseType = 'arrayBuffer';
        return this.http.post(GlobalAPIConstants.Host + apiPath, body, options).pipe(
            map((res: any) => {
                return new Blob([res]);
            }));
    }
    private extractData(res: Response): any {
        if (res === null) {
            return [];
        } else {
            const body: any = res;
            return body || {};
        }
    }

    private handleError(error: any) {
        // console.warn(error);
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        // checking if usr is logged in or not. If not then do not redirect. if yes then redirect
        const userData = localStorage.getItem(GlobalConstants.LocalStorageKey.Token);

        if (error.status === 401 && userData !== null) {
            //   localStorage.clear();
            // window.location.href = '/' + GlobalConstants.Routes.public.public;
            const errorData: ErrorModel = new ErrorModel({ ErrorCode: 401, ErrorMessage: 'Unauthorized Access', ErrorDetail: error });
            return observableThrowError(errorData);
        }
        if (error.status === 400 && error.hasOwnProperty('error')) {
            return observableThrowError(error.error);
        } else if (error.status === 503) {
            const errorData: ErrorModel = new ErrorModel({ ErrorCode: 503, ErrorMessage: 'Service Not Available', ErrorDetail: error });
            return observableThrowError(errorData);
        } else if (error.status === 0) {
            const errorData: ErrorModel = new ErrorModel({ ErrorCode: 0, ErrorMessage: 'Internet Not Connect', ErrorDetail: error });
            return observableThrowError(errorData);
        } else {
            const errorData: ErrorModel = new ErrorModel({ ErrorCode: error.status, ErrorMessage: 'Server Error', ErrorDetail: error });
            return observableThrowError(errorData);
        }
    }
}
