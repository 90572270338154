import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { GlobalConstants } from 'src/core/global-constants';
import { AuthenticatedGuard } from 'src/core/authenticated.guard';
import { AnonymousGuard } from 'src/core/anonymous.guard';
import { LowerCaseUrlSerializer } from './url-serializer';

const routes: Routes = [
  // {
  //   path: GlobalConstants.Routes.app + '/' + GlobalConstants.Routes.registrationForm,
  //   loadChildren: () => import('./main/registration-form/registration-form.module').then(m => m.RegistrationFormModule),
  //   canActivate: [AnonymousGuard]
  // },
  {
    path: GlobalConstants.Routes.admin,
    loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AnonymousGuard]
  },
  // {
  //   path: GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.login,
  //   loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule),
  //   canActivate: [AnonymousGuard]
  // },
  // {
  //   path: GlobalConstants.Routes.auth + '/post-login',
  //   loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule),
  //   canActivate: [AnonymousGuard]
  // },
  {
    path: 'app/registration/paralympic',
    pathMatch: 'full',
    loadChildren: () => import('./main/registration-form/registration-form.module').then(m => m.RegistrationFormModule),
    canActivate: [AnonymousGuard]
  },
  {
    path: 'app/registration/olympics/beijing-2022',
    pathMatch: 'full',
    loadChildren: () => import('./main/registration-form/registration-form.module').then(m => m.RegistrationFormModule),
    canActivate: [AnonymousGuard]
  },
  {
    path: 'app/registration/paralympics/beijing-2022',
    pathMatch: 'full',
    loadChildren: () => import('./main/registration-form/registration-form.module').then(m => m.RegistrationFormModule),
    canActivate: [AnonymousGuard]
  },
  // {
  //   path: 'app/registration/paralympics/beijing-2022',
  //   pathMatch: 'full',
  //   loadChildren: () => import('./main/winter-paralympics-2022-beijing-registration-form/winter-paralympics-2022-beijing-registration-form-routing.module').then(m => m.WinterParalympics2022BeijingRegistrationFormRoutingModule),
  //   canActivate: [AnonymousGuard]
  // },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   loadChildren: () => import('./main/registration-form/registration-form.module').then(m => m.RegistrationFormModule),
  //   canActivate: [AnonymousGuard]
  // },

  {
    path: '**',
    redirectTo: GlobalConstants.Routes.error404,
  }
];


const ChildRoutes: Routes = [
  {
    path: '**',
    loadChildren: () => import('./main/error/error.module').then(m => m.ErrorModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes),
  RouterModule.forChild(ChildRoutes)
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
   }]
})
export class AppRoutingModule { }
