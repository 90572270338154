import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/core/services/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'oltuserregistrationwebapp';
  isOlympic = false;
  constructor(private translate: TranslateService, private commonService: CommonService) {
    translate.setDefaultLang('en');
    this.translate.currentLang = 'en';
    this.commonService.setTitle('COMMON.Loading..');
    this.commonService.getOlympicType().subscribe(data=>{
      if(data !== null && data === false){
        this.isOlympic = true;
      }
    })
  }
}
