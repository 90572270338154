export const ApiPath = {
    getPersonalDetails: 'api/athlete/personal-details',
    putPersonalDetails: 'api/athlete/personal-details',
    getContactDetails: 'api/athlete/contact-details',
    putContactDetails: 'api/athlete/contact-details',
    postContactDetails: 'api/athlete/contact-details',

    getIdentityDetails: 'api/athlete/identity',
    putIdentityDetails: 'api/athlete/identity',
    postIdentityDetails: 'api/athlete/identity',
    uploadidentityMedia: 'api/athlete/identity/media',

    uploadProfilePicture: 'api/athlete/upload-photo',

    getSocialNetworkDetails: 'api/athlete/socialnetwork-details',
    putSocialNetworkDetails: 'api/athlete/socialnetwork-details',
    postSocialNetworkDetails: 'api/athlete/socialnetwork-details',

    getDisciplines: 'api/disciplines',
    getDisciplineDetails: 'api/athlete/discipline',
    putDisciplineDetails: 'api/athlete/discipline',
    postDisciplineDetails: 'api/athlete/discipline',
    getBodyMeasurement: 'api/body-measurements',

    getAaccessibilityQuestionsDetails: 'api/accessibility-questions',
    getAccessibilityDetails: 'api/athlete/accessibility-details',
    putAccessibilityDetails: 'api/athlete/accessibility-details',
    postAccessibilityDetails: 'api/athlete/accessibility-details',

    getAccomodationQuestion: 'api/accomodation-questions',
    getAccomodationDetails: 'api/athlete/accomodation-details',
    putAccomodationDetails: 'api/athlete/accomodation-details',
    postAccomodationDetails: 'api/athlete/accomodation-details',

    uploadPassportImage: 'api/athlete/upload-passport',
    downloadImage: 'api/admin/media',

    getSearchInList: 'api/admin/search-in-list',
    downloadMedia: 'api/admin/download-media',
    downloadExcel: 'api/admin/download-excel',
    move: 'api/admin/move',
    getDetails: 'api/admin/athlete',
    postFormData: 'api/athlete',
    getMedia: 'api/admin/media',
    deleteAthlete: '/api/admin/remove',

};
