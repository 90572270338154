export enum WheelChairOptionsEnum {
    // 'Yes (Manual)' = 1,
    // 'Yes (Electric)' = 2,
    // 'No' = 3
      'Manual' = 1,
    'Electric' = 2
}

export enum FoldableWheelChairOptionsEnum {
    // 'Yes (Manual)' = 1,
    // 'Yes (Electric)' = 2,
    // 'No' = 3
      'Yes' = 1,
    'No' = 2
}
export enum ListTypeEnum {
    longlist = 1,
    shortlist = 2,
}


export enum OlympicTypeEnum {
    Olympics = 1,
    Paralympics = 2,
}

