export class OAuthModel {
    Host: string;
    clientId: string;
    scope: string;
    requestAccessToken: boolean;
    showDebugInformation: boolean;
    sessionChecksEnabled: boolean;

    constructor(data) {
        this.Host = data.Host || '';
        this.clientId = data.clientId || '';
        this.scope = data.scope || '';
        this.requestAccessToken = data.requestAccessToken || true;
        this.showDebugInformation = data.showDebugInformation || false;
        this.sessionChecksEnabled = data.sessionChecksEnabled || false;
    }
}

export class EnvModel {
    production: boolean;
    hmr: boolean;
    Host: string;
    ShowVersion: boolean;
    ver: string;
    buildVer: number;
    Env: string;
    OcpAPIM: string;
    PageSize: number;
    oAuth: OAuthModel;
    constructor(data) {
        this.production = data.production || true;
        this.hmr = data.hmr || false;
        this.Host = data.Host || '';
        this.ShowVersion = data.ShowVersion || true;
        this.ver = data.ver || '';
        this.buildVer = data.buildVer || '';
        this.Env = data.Env || '';
        this.OcpAPIM = data.OcpAPIM || '';
        this.PageSize = data.PageSize || 50;
        this.oAuth = data.oAuth || new OAuthModel({});
    }
}


