import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataModel } from '../common.model';
import { GlobalConstants } from 'src/core/global-constants';

@Injectable()
export class AuthService {

    constructor(private router: Router) { }

    get currentUserProUser(): any {
        const getUserInfo = new UserDataModel(this.getUserInfo());
        return (getUserInfo.rolelevelids) ? getUserInfo.rolelevelids : '';
    }

    get isSystemUser(): any {
        return true;
    }

    get isLogin(): any {
        const getToken = localStorage.getItem(GlobalConstants.LocalStorageKey.Token);
        const getUserData = localStorage.getItem(GlobalConstants.LocalStorageKey.UserData);

        if (getToken && getUserData) {
            return true;
        } else {
            return false;
        }
    }

    public redirectToHome() {
        localStorage.removeItem(GlobalConstants.LocalStorageKey.Token);
        localStorage.removeItem(GlobalConstants.LocalStorageKey.UserData);
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigateByUrl('/');
    }

    public signOut() {
        localStorage.removeItem(GlobalConstants.LocalStorageKey.Token);
        localStorage.removeItem(GlobalConstants.LocalStorageKey.UserData);
        localStorage.clear();
    }

    get getUserInfo(): any {
        const userData = JSON.parse(localStorage.getItem(GlobalConstants.LocalStorageKey.UserData));
        return new UserDataModel(userData ? userData : []);
    }
}
