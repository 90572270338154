import { DownloadReqModel, ListModelRequestModel, MoveReqModel, DeleteReqModel, ListResponseModel } from '../common.model';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { GlobalConstants } from '../global-constants';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CommonService {
    private typeObs$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private titleService: Title, private translateService: TranslateService,
        private http: HttpClient, private httpService: HttpService) {
    }

    setTitle(title) {
        this.translateService.onLangChange.subscribe(data => {
            this.updateTitle(title);
        });
        this.updateTitle(title);
    }

    setOlympicType(type) {
        this.typeObs$.next(type);
    }

    getOlympicType(){
        return this.typeObs$.asObservable();
    }
    updateTitle(title) {
        this.translateService.get(title).subscribe(() => {
            this.titleService.setTitle(this.translateService.instant(title) + ' - '
                + this.translateService.instant('COMMON.PortalName'));
        });
    }

    getDisciplines(): Observable<any> {
        const timestamp = new Date().getDate() + '' + new Date().getMonth();
        const disciplinesData = this.http.get('assets/disciplines.txt?v=' + timestamp);
        return disciplinesData;
    }
    getFunctionTypes(): Observable<any> {
        const timestamp = new Date().getDate() + '' + new Date().getMonth();
        const functionTypesData = this.http.get('assets/functionTypes.txt?v=' + timestamp);
        return functionTypesData;
    }
    getCountryCode(): Observable<any> {
        const functionTypesData = this.http.get('assets/countryCode.json');
        return functionTypesData;
    }
    getLongListShortOfAthlete(PaginationObj: ListModelRequestModel): Observable<ListResponseModel> {
        if (PaginationObj === undefined) {
            PaginationObj = new ListModelRequestModel({});
        }
        const queryString: any = [];
        if (PaginationObj.listTypeId !== undefined && PaginationObj.listTypeId !== null) {
            queryString.push('listTypeId=' + PaginationObj.listTypeId);
        }
        if (PaginationObj.OlympicTypeId !== undefined && PaginationObj.OlympicTypeId !== null) {
            queryString.push('OlympicTypeId=' + PaginationObj.OlympicTypeId);
        }
        if (PaginationObj.Discipline !== undefined && PaginationObj.Discipline !== '' && PaginationObj.Discipline !== 'All') {
            queryString.push('Discipline=' + PaginationObj.Discipline);
        }
        if (PaginationObj.FunctionType !== undefined && PaginationObj.FunctionType !== '' && PaginationObj.FunctionType !== 'All') {
            queryString.push('FunctionType=' + PaginationObj.FunctionType);
        }
        if (PaginationObj.PageNumber !== undefined && PaginationObj.PageNumber !== null) {
            queryString.push('PageNumber=' + PaginationObj.PageNumber);
        }
        if (PaginationObj.PageSize !== undefined && PaginationObj.PageSize !== null) {
            queryString.push('PageSize=' + PaginationObj.PageSize);
        }
        if (PaginationObj.SortBy !== undefined && PaginationObj.SortBy !== '') {
            queryString.push('SortBy=' + PaginationObj.SortBy);
        }
        if (PaginationObj.SortOrder !== undefined && PaginationObj.SortOrder !== null) {
            queryString.push('SortOrder=' + PaginationObj.SortOrder);
        }
        const apiPath = `${GlobalConstants.ApiPath.getSearchInList}?${queryString.join('&')}`;
        return this.httpService.get<ListResponseModel>(apiPath, true);
    }
    deleteAthlete(reqModel: DeleteReqModel): Observable<any> {
        const queryString: any = [];
        if (reqModel.athleteIds !== undefined && reqModel.athleteIds !== null) {
            queryString.push('athleteIds=' + reqModel.athleteIds);
        }
        const apiPath = `${GlobalConstants.ApiPath.deleteAthlete}?${queryString.join('&')}`;
        return this.httpService.delete<any>(apiPath, true);
    }
    downloadMedia(reqModel: DownloadReqModel): Observable<any> {
        const apiPath = `${GlobalConstants.ApiPath.downloadMedia}`;
        return this.httpService.downloadPost(apiPath, reqModel, true);
    }
    downloadExcel(reqModel: DownloadReqModel): Observable<any> {
        const apiPath = `${GlobalConstants.ApiPath.downloadExcel}`;
        // return this.httpService.postForDownloadFile<any>(apiPath, reqModel, true);
        return this.httpService.downloadPost(apiPath, reqModel, true);
    }
    moveToLongShortList(reqModel: MoveReqModel): Observable<any> {
        const apiPath = `${GlobalConstants.ApiPath.move}`;
        return this.httpService.post<any>(apiPath, reqModel, true);
    }
    getDetails(athleteId) {
        const queryString: any = [];
        queryString.push('athleteId=' + athleteId);
        const apiPath = `${GlobalConstants.ApiPath.getDetails}?${queryString.join('&')}`;
        return this.httpService.get<any>(apiPath, true);
    }
    getMedia(MediaPathUrl: string) {
        const queryString: any = [];
        queryString.push('MediaPathUrl=' + MediaPathUrl);
        const apiPath = `${GlobalConstants.ApiPath.getMedia}?${queryString.join('&')}`;
        return this.httpService.download(apiPath, '', true);
    }
}
