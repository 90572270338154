<div class="header" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center" fxLayoutAlign.lt-md="center center">

    <div class="left-header" fxLayout="row wrap" fxLayoutGap="30px" fxLayoutAlign="center center"
        fxLayoutAlign.gt-xs="space-between center">
        <div class="norge-logo">
            <a [routerLink]="navigateUrl">
                <img class="mt-10" [src]="isParalympic === 'true' ? '/assets/images/logo/logo_para.png' :  '/assets/images/logo/norge-logo.png'" alt="logo">
            </a>
        </div>

        <div class="olt-registration" fxLayout="row" fxLayoutAlign="center center">
            {{title}}
        </div>
    </div>

</div>