import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from './directives/directives';
import { PipesModule } from './pipes/pipes.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './services/auth.service';
import { HttpService } from './services/http.service';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DirectivesModule,
        PipesModule,
        HttpClientModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DirectivesModule,
        PipesModule,
        HttpClientModule,
        TranslateModule,
    ],
    providers: [ AuthService, HttpService]
})
export class SharedModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
