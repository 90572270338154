import { EnvModel } from './env.model';
import { GlobalConstants } from './global-constants';
const env = localStorage.getItem(GlobalConstants.LocalStorageKey.EnvConfig);
let envData = new EnvModel({});
if (env) {
    envData = new EnvModel(JSON.parse(atob(env)));
}
export const GlobalAPIConstants = {
    Host: envData.Host,
    Headers: {
        ContentTypes: {
            ApplicationOctetStream: {
                'Content-Type': 'application/octet-stream; charset=utf-8'
            },
            ApplicationJSON: { 'Content-Type': 'application/json' }
        },
    },
    URLParams: {
        PageNumber: 'pageNumber',
        SearchString: 'searchString',
        SortBy: 'sortBy'
    },
    HeaderValues: {
        APIVersion: '1.0',
        ApplicationJSON: 'application/json',
        ApplicationOctetStream: 'application/octet-stream',
        AuthKey: '',
        AppVersion: '1.0',
        OcpAPIM: envData.OcpAPIM
    },
    ShowVersion: envData.ShowVersion,
    Env: envData.Env,
    Ver: envData.ver + '-' + envData.buildVer,
    PageSize: envData.PageSize,
    MaxPageSize: 3000,
    oAuth: envData.oAuth
};

