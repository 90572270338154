import { ListTypeEnum, OlympicTypeEnum } from './common-enums';

export class ErrorModel {
    ErrorCode: number;
    ErrorMessage: string;

    constructor(data) {
        this.ErrorCode = data.ErrorCode || -1;
        this.ErrorMessage = data.ErrorMessage || '';

    }
}

export class UserDataModel {
    sid: string;
    // tslint:disable-next-line: variable-name
    preferred_username: string;
    email: string;
    // tslint:disable-next-line: variable-name
    given_name: string;
    // tslint:disable-next-line: variable-name
    family_name: string;
    birthdate: string;
    rolelevelids: string;
    personid: number;
    userids: string;
    orgids: string;
    constructor(data) {
        this.sid = data.sid || '';
        this.preferred_username = data.preferred_username || '';
        this.email = data.email || '';
        this.given_name = data.given_name || '';
        this.family_name = data.family_name || '';
        this.birthdate = data.birthdate || '';
        this.rolelevelids = data.rolelevelids || '';
        this.userids = data.userids || '';
        this.personid = data.personid || null;
        this.orgids = data.orgids || '';
    }
}

export class ListModelRequestModel {
    listTypeId: ListTypeEnum;
    OlympicTypeId: OlympicTypeEnum;
    Discipline: string;
    FunctionType: string;
    PageNumber: number;
    PageSize: number;
    SortBy: string;
    SortOrder: number;
    constructor(data) {
        this.listTypeId = data.listTypeId || ListTypeEnum.longlist;
        this.OlympicTypeId = data.OlympicTypeId || OlympicTypeEnum.Olympics;
        this.Discipline = data.Discipline || '';
        this.FunctionType = data.FunctionType || '';
        this.PageNumber = data.PageNumber || 1;
        this.PageSize = data.PageSize || 3000;
        this.SortBy = data.SortBy || '';
        this.SortOrder = data.SortOrder || 0;
    }
}

export interface ListResponseModel {
    pageNumber: number;
    pageSize: number;
    sortOrder: number;
    totalPages: number;
    totalCount: number;
    items: ItemModel[];
}

export interface ItemModel {
    id: number;
    listTypeId: number;
    discipline: string;
    functionType: string;
    givenName: string;
    familyName: string;
}

export interface DownloadReqModel {
    athleteIds: number[];
    listTypeId: number;
}
export interface MoveReqModel {
    athleteIds: number[];
    listTypeId: number;
}
export interface DeleteReqModel {
    athleteIds: number[];
}

