import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { CommonService } from 'src/core/services/common.service';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'src/core/global-constants';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/core/services/auth.service';
import { UserDataModel } from 'src/core/common.model';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentLang = '';
  isParalympic = '';
  isWinterParalympic = '';
  title = '';
  url:any;
  navigateUrl = '/app/registration/olympics/beijing-2022';

  constructor(
    private commonService: CommonService,
    private router: Router,private Location:Location,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document) {
    this.currentLang = this.translate.currentLang;
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.commonService.setTitle('COMMON.Loading..');
        }
      });
  }

  ngOnInit() {
    this.url=this.Location.path();
    localStorage.clear();
   
    if (window.location.href.toLowerCase().indexOf(GlobalConstants.Paralympic.toLowerCase()) !== -1) {
      localStorage.setItem(GlobalConstants.isParalympic, 'true');
    }
    if (window.location.href.toLowerCase().indexOf(GlobalConstants.WinterParalympic.toLowerCase()) !== -2) {
      localStorage.setItem(GlobalConstants.isWinterParalympic, 'true');
    }
    this.isWinterParalympic = localStorage.getItem(GlobalConstants.isWinterParalympic) ? localStorage.getItem(GlobalConstants.isWinterParalympic) : null;
    if(this.isWinterParalympic){
      this.navigateUrl = '/app/registration/paralympics/beijing-2022';
    }
    
    if (window.location.href.toLowerCase().indexOf(GlobalConstants.Paralympic.toLowerCase()) !== -1) {
      this.title = 'Registration for Tokyo 2021 Paralympic Games';
    } 
   
    else {
      // this.title = 'Registration for Beijing 2022 Winter Olympics';
      this.title = 'Registration for Olympic Winter Games Beijing 2022';
    }
    if (this.url=='/app/registration/paralympics/beijing-2022') {
       this.title = 'Registration for   Winter Paralympic Games Beijing 2022';
     }
    if (window.location.href.toLowerCase().indexOf(GlobalConstants.List.toLowerCase()) !== -1) {
      if (window.location.href.toLowerCase().indexOf(GlobalConstants.Paralympic.toLowerCase()) !== -1) {
        this.title = 'List Management for Winter Paralympic Games Beijing 2022';
      } else {
        // this.title = 'List Management for Beijing 2022 Winter Olympics';
        this.title = 'List Management for Winter Paralympic  Games Beijing 2022';
      }
    }
    this.document.body.classList.remove('login-body');
  }

  changeLanguage() {
    if (this.translate.currentLang === 'no') {
      this.currentLang = 'en';
      this.translate.use('en');
    } else {
      this.currentLang = 'no';
      this.translate.use('no');
    }
  }
}
