import { NgModule } from '@angular/core';
import { PositiveFractionDirective } from './fuse-numberonly/positive-fraction-only.directive';


@NgModule({
    declarations: [
        PositiveFractionDirective
    ],
    imports: [],
    exports: [
        PositiveFractionDirective
    ]
})
export class DirectivesModule {
}
