import { Routes } from './routes';
import { ApiPath } from './api';
export const GlobalConstants = {
    Routes,
    otherOption: 'Other',
    LocalStorageKey: {
        EnvConfig: 'e',
        AuthKey: 'a',
        Token: 't',
        oAuthToken: 'access_token',
        UserData: 'u',
        CurrentOrgID: 'o',
        CurrentPersonID: 'p',
        AutoLogin: 'al',
    },
    ApiPath,
    // tslint:disable-next-line: quotemark
    EmailRgex: "^([\\w\\-\\.\\+\\=\\^\\_\\æ\\ø\\å\\Æ\\Ø\\Å]+)@((\\[([0-9]{1,3}\\.){3}[0-9]{1,3}\\])|(([\\w\\-]+\\.)+)([a-zA-Z]{2,9}))$",
    PassportRgex: '^([a-zA-Z0-9-]+)$',
    telephoneRgex: '^([0-9]+)$',
    PostalCodeRegex: '^([a-zA-Z0-9 ]+)$',
    // /^[a-zA-Z0-9\-\s]+$/
    // PassportRgex: '^([A-Z]{2}-[0-9]{7})$',
    FormData: 'formData',
    Paralympic: 'Paralympic',
    WinterParalympic: 'WinterParalympic',
    List: 'List',
    isParalympic: 'isParalympic',
    isWinterParalympic: 'isWinterParalympic',
    OnlyPostiveNumericWithPrecision: '^(?!-)?([0-9]+(?:[\\.][0-9]{0,2})?|\\.[0-9]{0,2})$',
};
